<template>
  <div>
    <p class="text-h3">Place to test components</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //
    };
  }
};
</script>

<style lang="scss" scoped>
//
</style>